#root::backdrop {
  background-color: white;
  overflow: auto;
}

#root {
  background-color: rgba(255, 255, 255, 0);
  overflow: auto;
  scroll-behavior: smooth;
}

@font-face {
  font-family: helvetica;
  src: url('./assets/fonts/helvetica.ttf');
}

.logo {
  font-family: helvetica !important;
  text-align: center;
  background-color: aqua;
}
